import Vue from 'vue';
import Router from 'vue-router';
import routers from './routers';
import jweixin from '@/features/weixin/jweixin';
import setTitle from '@/features/common/utils/setTitle';
import { Toast } from 'vant';
import useMeStore from '@/features/auth/useMeStoreAdapter';
import {
  plpStart,
  plpEnd,
} from '@/features/common/components/pageLoadProgress.vue';

jweixin();

const originalPush = Router.prototype.push;
Router.prototype.push = function (location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(Router);

const router = new Router({
  mode: 'hash',
  routes: routers,
});

const meStore = useMeStore();

router.beforeEach((to, form, next) => {
  if (
    to.path != '/delAccInfo' &&
    meStore.me?.del_id &&
    meStore.me?.del_id > 0
  ) {
    return next({ path: '/delAccInfo' });
  }
  plpStart();
  next();
});
router.afterEach((to) => {
  plpEnd();
  setTitle(to.meta?.title);
});

router.onError(async (err) => {
  Toast.clear();
  Toast('页面加载失败：' + err.message, {
    duration: 3000,
  });
});

export default router;
